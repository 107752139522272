export const toggleMenu = e => {
  const nav = document.getElementById(e.target.dataset.target);
  nav.classList.toggle('visible');
  document.documentElement.classList.toggle('no-scroll');
};

export const initToggle = () => {
  let toggleEls = document.querySelectorAll('.toggle');
  toggleEls.forEach(el => el.addEventListener('click', toggleMenu));
};
